import React from 'react';
import Layout from '../components/Layout';

const DuesSinglePage = () => {
    return (
        <Layout>
            <div className="container-fluid p-0" id="page-top">
                <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
                    <div className="w-100">
                        <h1 className="mb-0">Give <span className="text-primary">Today</span></h1>
                        <h2 className="mb-5">Lambda Iota Alumni Association</h2>
                        <p className="mb-5">This form will handle one-time (non-recurring) payments between $10 and $2500.</p>

                        <div className="bg-red">
                            <p className="mb-5" align="center">Enter Payment Information Below</p>
                            <form id="payment-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" name="email"
                                        placeholder="email@example.com" />
                                </div>
                                <div className="form-group">
                                    <input type="number" className="form-control" min="10.00" step="0.01" max="2500"
                                        value="<%= amount %>" id="amount" name="amount" placeholder="Donation Amount" />
                                </div>
                                <div id="card-element" className="input form-control">

                                </div>
                                <div className="form-group" align="center">
                                    <br />
                                    <button id="card-button" className="btn btn-success mb-2">Submit Payment</button>
                                </div>
                                <p id="payment-result">

                                </p>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default DuesSinglePage;

/*

<script src="https://js.stripe.com/v3/"></script>

    <script>

        const stripe = Stripe('<%= stripeKey %>');

        const elements = stripe.elements();
        const cardElement = elements.create('card', {
            style: {
                base: {
                    backgroundColor: '#ffffff',
                    iconColor: '#555',
                    color: '#000',
                    fontWeight: 500,
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: '#fce883',
                    },
                    '::placeholder': {
                        color: '#555',
                    },
                },
                invalid: {
                    iconColor: '#FFC7EE',
                    color: '#FFC7EE',
                },
            },
        });

        cardElement.mount('#card-element');

        const form = document.getElementById("payment-form");

        const submitButton = document.getElementById("card-button");

        function disableSubmitButton() {
            submitButton.disabled = true;
            submitButton.textContent = "Processing";
        }

        function enableSubmitButton() {
            submitButton.disabled = false;
            submitButton.textContent = "Submit Payment";
        }

        let resultContainer = document.getElementById('payment-result');
        cardElement.on('change', function (event) {
            if (event.error) {
                resultContainer.textContent = event.error.message;
            } else {
                resultContainer.textContent = '';
            }
        });

        form.addEventListener('submit', async event => {
            disableSubmitButton();
            console.log(submitButton);
            const name = document.getElementById("name").value;
            const email = document.getElementById("email").value;
            const paymentAmount = document.getElementById("amount").value * 100;
            event.preventDefault();
            resultContainer.textContent = '';
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            handlePaymentMethodResult(result, name, email, paymentAmount);
        });

        const handlePaymentMethodResult = async ({ paymentMethod, error }, name, email, paymentAmount) => {
            if (error) {
                // An error happened when collecting card details, show error in payment form
                resultContainer.textContent = result.error.message;
            } else {
                // Send paymentMethod.id to your server (see Step 3)
                const response = await fetch("/dues/singles/methods", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ payment_method_id: paymentMethod.id, donorName: name, donorEmail: email, amount: paymentAmount })
                });

                const responseJson = await response.json();

                handleServerResponse(responseJson);
            }
        };

        const handleServerResponse = async responseJson => {
            if (responseJson.error) {
                // An error happened when charging the card, show it in the payment form
                resultContainer.textContent = responseJson.error;
            } else if (responseJson.requiresAction) {
                // Use Stripe.js to handle the required card action
                const { error: errorAction, paymentIntent } =
                    await stripe.handleCardAction(responseJson.clientSecret);

                if (errorAction) {
                    // Show error from Stripe.js in payment form
                } else {
                    // The card action has been handled
                    // The PaymentIntent can be confirmed again on the server
                    const serverResponse = await fetch('/dues/singles/intents', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ payment_intent_id: paymentIntent.id })
                    });
                    handleServerResponse(await serverResponse.json());
                }
            } else {
                // Show a success message
                resultContainer.textContent = 'Success!';
                window.location = "/dues-single-finished";
            }
            enableSubmitButton();
        };
    </script>

    */